<template>

<div >
     
    <h1>hello</h1>

</div>



</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  }
}
</script>
